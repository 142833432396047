@import url(./font/styles.css);
html, body {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	background: #f0f0f0;
	overscroll-behavior: none;
}

#root {
	height: 100%;
}

a {
	text-decoration: none;
}

input[type="email"], input[type="string"] {
	font-family: sans-serif;
}

.clickable {
	cursor: pointer;
}

.disabled {
	opacity: 0.5;
	cursor: not-allowed;
}
