
@font-face {
  font-family: 'ComicKings';
  src: url('ComicKings.eot') format('embedded-opentype');
  src:  url('ComicKings.woff') format('woff'),
        url('ComicKings.ttf')  format('truetype'),
        url('ComicKings.svg#ComicKings') format('svg');
  font-weight: normal;
  font-style: normal;
}
